<template>
    <div class="mb-4">
        Items
        <div class="d-flex flex-column items-wrapper">
            <Item v-for="(item, index) in itemList"
                  :item="item"
                  :stockStatuses="stockStatuses"
                  :stock-locations="stockLocations"
                  :routes="routes"
                  :removeStockBreakdownLink="kitItems.hasOwnProperty(item.id)"
                  :disabled="kitItems.hasOwnProperty(item.id)"
                  :assign-to-users="assignToUsers"
                  @status-changed="statusChange(index, item.id, $event)"
                  @location-changed="locationChange(index, $event)"
            >
                <template v-if="kitItems.hasOwnProperty(item.id)"
                          #description>
                    <div class="text-muted">
                        Kit's stock status is automatically decided by summarising the listed kit components:
                        <ul>
                            <li v-for="(component) in kitItems[item.id]">
                                {{ component.part.label }}
                            </li>
                        </ul>
                    </div>
                </template>
            </Item>
        </div>
    </div>
</template>

<script setup>
import { reactive } from "vue";
import Item from "@/components/admin/quotes/stockCheck/item.vue";

const props = defineProps({
    items: Object,
    stockStatuses: {
        type: Array,
        required: true,
    },
    stockLocations: {
        type: Array,
        required: true,
    },
    routes: Object,
    assignToUsers: {
        type: Array,
        default: () => [],
    },
});
let itemList = reactive(props.items);
let kitItems = {};
for (const [key, item] of Object.entries(props.items)) {
    if (item.kit_quote_item) {
        if (kitItems.hasOwnProperty(item.kit_quote_item)) {
            kitItems[item.kit_quote_item].push(item);
        } else {
            kitItems[item.kit_quote_item] = [item];
        }
    }
}

function locationChange(index, location) {
    itemList[index].stock_location = location;
}

const statusChange = function (index, id, status) {
    itemList[index].stock_check_status = status;
    for (const [kitId, items] of Object.entries(kitItems)) {
        // Default: In stock
        let finalStockStatus = props.stockStatuses[2].value;
        items.every((item) => {
            // Out of stock?
            if (item.stock_check_status === props.stockStatuses[0].value) {
                finalStockStatus = props.stockStatuses[0].value;
                return false;
            }

            // Awaiting stock check?
            if (item.stock_check_status === props.stockStatuses[1].value) {
                finalStockStatus = props.stockStatuses[1].value;
                return false;
            }

            return true;
        })
        for (const [index, item] of Object.entries(itemList)) {
            if (item.id == kitId) {
                itemList[index].stock_check_status = finalStockStatus;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.items-wrapper {
    & > * {
        border-bottom: 1px solid #e4e7e7;
    }

    & > *:last-child {
        border-bottom: unset;
    }
}
</style>
