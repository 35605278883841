
<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import TableFilter from '@/components/admin/datatable/tableFilter.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref, reactive } from "vue";

const props = defineProps({
    ajaxUrl: String,
    roles: {
        type: Array,
        default: () => [],
    },
});

const orderBy = ref('name');
const orderAsc = ref(true);
const limit = ref(10);
const searchTerm = ref(null);
const customFilter = reactive({});

function filterChanged(key, value) {
    if (customFilter[key] == value) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <TableFilter
                    label="Role"
                    :options="roles"
                    @change="(value, label) => filterChanged('role', value)"
                />
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all users..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <Datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter">
        <template #headings>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Name</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="email">Email</SortableTh>
            <th>Role</th>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="created_at">Created at</SortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="user in data"
                :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td v-html="user.role"></td>
                <td>{{ user.created_at }}</td>
                <td v-html="user.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
