<script setup>
import { reactive, ref, watch, computed } from "vue";
import FormSelect from '@/components/admin/utilities/formSelect.vue';
import DropShipItem from '@/components/admin/supplierOrders/formWizard/dropShipItem.vue';
import Item from '@/components/admin/supplierOrders/formWizard/item.vue';

const emit = defineEmits(['stepValueChanged']);
const props = defineProps({
    routes: Object,
    extras: Object,
    stepsData: Object,
});

const lines = reactive(props.extras.preselected.items ? props.extras.preselected.items : []);
const itemLinesRef = ref(null);
const dropShippingOrder = ref(props.extras.preselected.drop_shipping_order ? props.extras.preselected.drop_shipping_order : null);
const selectedDropShipLines = ref([]);
const availableDropShipLines = ref([]);
const processedDropShipLines = ref([]);

const dropShippingOrdersUrl = computed(() => {
    let url = new URL(props.routes.customer_orders);
    url.searchParams.append('drop-shipping', 1);
    return url.href;
});

const hasPartsSelected = computed(() => {
    return lines.some(line => line.part);
});

watch(
    () => dropShippingOrder.value,
    (newValue) => dropShippingOrderChange(newValue),
);

watch(
    selectedDropShipLines,
    () => updateParentWithValues(),
    { deep: true }
);

watch(
    () => ({ ...lines }),
    () => updateParentWithValues(),
    { deep: true }
);

function addLine() {
    lines.push({
        id: Math.floor(Math.random() * Date.now()).toString(36),
        quantity: 1,
    });
}

function removeLine(removeAtIndex) {
    lines.splice(removeAtIndex, 1);
    if (lines.length === 0) {
        addLine();
    }
}

function addToDropShip(item) {
    // It feels more natural to show the selected items in order it was added by user
    selectedDropShipLines.value.push(item);
    // Remove from available items
    availableDropShipLines.value = availableDropShipLines.value.filter(line => line.id !== item.id);
    updateParentWithValues();
}

function removeFromDropShip(item) {
    // It feels more natural to keep the selected items in order it was loaded in
    availableDropShipLines.value.push(item);
    availableDropShipLines.value.sort((a, b) => a.id - b.id);
    // Remove from selected items
    selectedDropShipLines.value = selectedDropShipLines.value.filter(line => line.id !== item.id);
    updateParentWithValues();
}

function dropShippingOrderChange(newDropShippingOrder) {
    selectedDropShipLines.value.value = [];
    availableDropShipLines.value = [];
    processedDropShipLines.value = [];
    if (newDropShippingOrder && newDropShippingOrder.items.length > 0) {
        availableDropShipLines.value = newDropShippingOrder.items.filter((item) => {
            return item.unallocated_drop_ship_quantity > 0;
        });
    }
    updateParentWithValues();
}


const updateParentWithValues = function () {
    let stepData = {};
    if (dropShippingOrder.value) {
        stepData.drop_shipping_order = JSON.parse(JSON.stringify(dropShippingOrder.value));
        stepData.items = selectedDropShipLines.value
            .filter((line) => line.quantity <= line.unallocated_drop_ship_quantity)
            .map((line) => {
                let lineData =  JSON.parse(JSON.stringify(line));
                lineData.drop_shipped_item_id = line.id;
                return lineData;
            });

    } else {
        stepData.items = [];

        for (const [key, line] of Object.entries(lines)) {
            let lineData = JSON.parse(JSON.stringify(line));
            if (!lineData.part) {
                continue;
            }
            stepData.items.push(lineData);
        }

    }

    emit('stepValueChanged', stepData);
}

if (lines.length > 0) {
    updateParentWithValues();
} else {
    // Add blank line to save user clicking "Add Item" initially
    addLine();
}
</script>

<template>
    <div class="mb-4">
        <template v-if="!hasPartsSelected">
            <label for="drop_shipping_order"
                   class="control-label">Drop ship customer order</label>
            <FormSelect name="drop_shipping_order"
                        v-model="dropShippingOrder"
                        :url="dropShippingOrdersUrl"
                        placeholder="Select customer order"
                        helper-text="Select customer order if you are adding a drop ship supplier order. Search for <kbd>#</kbd> to look for best matches."
                        clearable
            />
            <template v-if="dropShippingOrder">
                <div class="table-responsive-xl mt-3">
                    <table class="table table-bordered table-striped table-vcenter mb-0">
                        <thead>
                            <tr>
                                <th>Part</th>
                                <th class="text-center"
                                    width="150">Qty</th>
                                <th style="width:2%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-uppercase text-center fw-bold"
                                    colspan="3">
                                    Drop Shipped Items
                                </td>
                            </tr>
                            <tr v-if="selectedDropShipLines.length === 0">
                                <td class="text-center"
                                    colspan="3">
                                    No items selected
                                </td>
                            </tr>
                            <DropShipItem v-else
                                          v-for="(item, index) in selectedDropShipLines"
                                          :item="item"
                                          :key="item.id"
                                          isIncludedInDropShip
                                          @removeFromDropShip="() => removeFromDropShip(item)"
                            />
                        </tbody>
                        <tbody v-if="availableDropShipLines.length > 0">
                            <tr>
                                <td class="text-uppercase text-center fw-bold"
                                    colspan="3">
                                    Available Items
                                </td>
                            </tr>
                            <DropShipItem v-for="(item, index) in availableDropShipLines"
                                          :item="item"
                                          :key="item.id"
                                          @addToDropShip="() => addToDropShip(item)"
                            />
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
        <h5 v-if="!hasPartsSelected && dropShippingOrder === null"
            class="center-line-text my-3">OR</h5>
        <template v-if="dropShippingOrder === null">
            <div class="table-responsive-xl">
                <table class="table table-bordered table-striped table-vcenter mb-0">
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th style="width: 25%;">Reference</th>
                            <th style="width: 10%;" class="text-center">Qty</th>
                            <th style="width: 14%;">RRP</th>
                            <th style="width: 10%;">Discount</th>
                            <th style="width: 2%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <Item v-for="(item, index) in lines"
                              :index="index"
                              :item="item"
                              :partsUrl="routes.parts"
                              :key="item.id"
                              ref="itemLinesRef"
                              @removeItem="removeLine"
                        />
                    </tbody>
                </table>
            </div>
            <button class="btn btn-sm btn-secondary mt-3"
                    type="button"
                    @click="addLine">
                Add Item
            </button>
        </template>
    </div>
</template>
