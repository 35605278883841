<script setup>
import {ref, watch} from "vue";

const emit = defineEmits(['toggleChanged']);

const props = defineProps({
    title: {
        type: String,
        default: "",
    },
    id: {
        type: [Number, String],
        required: true,
    },
    preselected: {
        type: Boolean,
        default: false,
    },
    previewMode: {
        type: Boolean,
        default: false,
    },
});

const isChecked = ref(props.preselected);
watch(isChecked, (value) => emit('toggleChanged', value));

const setToggleState = function (value) {
    isChecked.value = value;
}

defineExpose({
    toggleState: setToggleState,
});
</script>

<template>
    <div class="d-flex">
        <div v-if="previewMode">
            {{ title }}
        </div>
        <div v-else
             class="form-check form-switch">
            <input class="form-check-input"
                   type="checkbox"
                   value="1"
                   :id="'vehicle_' + id"
                   :name="'vehicles[' + id + ']'"
                   v-model="isChecked">
            <label class="form-check-label"
                   :for="'vehicle_' + id">{{ title }}</label>
        </div>

    </div>
</template>
