<script setup>
import { ref, watch, reactive, nextTick, onMounted } from "vue";
import FormAddress from '@/components/admin/addresses/address.vue';

const emit = defineEmits(['step-value-changed']);

const props = defineProps({
    routes: {
        type: Object,
        default: () => ({}),
    },
    extras: {
        type: Object,
        default: () => ({}),
    },
    stepsData: {
        type: Object,
        required: true,
    },
    isInitialLoadingFinished: {
        type: Boolean,
        default: false,
    },
});

let customer = props.extras.preselected.customer?.id;
let deliveryAddress = {};
const deliveryAddressRef = ref(null);
const deliveryAddressRoute = ref(props.routes.delivery_address.replace('%customer%', customer));
const deliveryAddressesRoute = ref(props.routes.delivery_addresses.replace('%customer%', customer));
let isMounted = false;

let stepData = reactive({ delivery: {} });
const addressChanged = function (key, data) {
    let addressData = JSON.parse(JSON.stringify(Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != ""))));
    delete addressData.value;
    delete addressData.actions;
    if (Object.keys(addressData).length <= 1) {
        stepData[key] = null;
    } else {
        stepData[key] = addressData;
    }
    updateParentWithValues();
}

const updateParentWithValues = function () {
    emit('step-value-changed', stepData);
}

onMounted(function () {
    isMounted = true;
});

if (props.extras.preselected.deliveryAddress) {
    deliveryAddress = props.extras.preselected.deliveryAddress;
    addressChanged('delivery', deliveryAddress);
}
</script>

<template>
    <div class="d-flex flex-column flex-sm-row justify-content-around gap-3">
        <div class="d-flex align-items-baseline justify-content-center flex-basis-100">
            <FormAddress
                :address="deliveryAddress"
                label="Delivery Address"
                :ajax-url="deliveryAddressRoute"
                :search-url="deliveryAddressesRoute"
                show-reference
                allow-edit
                @address-changed="(data) => addressChanged('delivery', data)"
                ref="deliveryAddressRef"
            />
        </div>
    </div>
</template>
