<script setup>
import {onMounted, ref} from "vue";
import {Eventbus} from "@/utilities/eventbus.js";

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    initialContent: {
        type: String,
        default: "",
    },
    defaultValue: {
        type: String,
        default: "",
    },
    hasBeenOverridden: {
        type: Boolean,
        default: false,
    },
});

const updatedText = ref(props.initialContent);
const shouldReset = ref(null);
const hasChanged = ref(false);

onMounted(() => {
    Eventbus.on('kit-component-part-selected', ({oldPart, part}) => {
        let lines = updatedText.value.trim().split("\n").filter(line => line.trim() !== "");

        if (oldPart && oldPart[props.name]) {
            lines = lines.filter(line => line !== oldPart[props.name]);
        }

        if (!part.delete) {
            lines.push(part[props.name]);
        } else {
            lines = lines.filter(line => line !== part[props.name]);
        }

        updatedText.value = lines.join("\n");
    });
});

function reset() {
    updatedText.value = props.defaultValue;
    shouldReset.value = true;
}
</script>

<template>
    <div>
        <div class="position-relative">
            <button type="button" v-if="hasBeenOverridden || hasChanged"
                    class="btn btn-sm btn-alt-secondary position-absolute"
                    style="right: 0; top: -2.2rem;"
                    @click="reset"
            >
                Reset
            </button>
            <textarea
                v-model="updatedText"
                :name="name"
                class="form-control"
                rows="5"
                @input="hasChanged = true"
            ></textarea>
        </div>
        <p v-if="(hasBeenOverridden || hasChanged) && !shouldReset" class="help-block text-muted">
            This has been manually set and will override the components in the kit.
        </p>
        <p v-else class="help-block text-muted">
            This is generated from the components in the kit.
        </p>
        <input type="checkbox" class="d-none" :name="`${name}_has_changed`" value="1" :checked="hasChanged">
        <input type="checkbox" class="d-none" :name="`${name}_reset`" value="1" :checked="shouldReset">
    </div>
</template>
