import { useFetch } from '@/utilities/useFetch.js';

Element.prototype.ajaxForm = function (customOptions = {}) {
    if (this.nodeName !== 'FORM') {
        console.error('ajaxForm must be attached to <form> element');
        return;
    }
    let options = {
        ...{
            before: () => { },
            after: () => { },
            success: (responseData) => {
                if (responseData.redirect) {
                    window.location = responseData.redirect;
                }
            },
            error: () => { },
            displayValidation: true,
            autoDisableSubmitter: true,
            validationTarget: null,
        },
        ...customOptions
    };

    let form = this;

    form.addEventListener('submit', function (e) {
        e.preventDefault();
        submitAjaxForm(e.submitter);
    });

    form.addEventListener('ajax-submit', function (e) {
        let extraData = e.detail;
        let submitter = e.detail.submitter ? e.detail.submitter : null;
        delete extraData['submitter'];
        submitAjaxForm(submitter, extraData);
    });

    const _before = function (triggerElement) {
        // Remove old form errors.
        if (options.validationTarget) {
            options.validationTarget.parentNode.querySelectorAll('.form-error').forEach(e => e.remove());
        } else {
            form.querySelectorAll('.form-error').forEach(e => e.remove());
        }

        // Disable submitter.
        if (triggerElement && options.autoDisableSubmitter) {
            triggerElement.dataset.originalHTML = triggerElement.innerHTML;
            triggerElement.disabled = true;
            // Keep button width for styling purposes.
            triggerElement.style.minWidth = triggerElement.offsetWidth + 'px';
            triggerElement.innerHTML = '<i class="fa fa-spin fa-spinner"></i>';
        }
        options.before();
    }
    const _after = function (triggerElement, isSuccess) {
        // Re-enable the button only if custom success callback is pass as otherwise default redirect will be used
        // so there is no point to re-enable submission button.
        if (triggerElement && options.autoDisableSubmitter && (!isSuccess || customOptions.hasOwnProperty('success'))) {
            triggerElement.innerHTML = triggerElement.dataset.originalHTML;
            triggerElement.disabled = false;
            triggerElement.style.removeProperty('min-width');
        }
        options.after();
    }
    const submitAjaxForm = async function (triggerElement = null, extraData = null) {
        _before(triggerElement);
        // Check for any files being uploaded with fileUpload.vue.
        // If there are any being processed, wait for it to finish.
        // Scan for it every 200ms.
        while (form.querySelector('.file-upload-container.processing') !== null) {
            await new Promise(r => setTimeout(r, 200));
        }

        let formData = new FormData(form);
        if (extraData) {
            const appendObjectToFormData = function (object, parentKey = []) {
                for (const [key, value] of Object.entries(object)) {
                    let formKey = parentKey.slice(); // Create copy.
                    formKey.push(key);
                    if (typeof value === 'object' && value !== null) {
                        appendObjectToFormData(value, formKey);
                    } else {
                        formKey = formKey.map((x, index) => index == 0 ? x : '[' + x + ']');
                        // FormData parses null as string so you have to pass empty string instead.
                        formData.append(formKey.join(''), value === null ? '' : value);
                    }
                }
            }
            appendObjectToFormData(extraData);
        }

        const res = await useFetch(form.action, {
            method: 'POST',
            body: formData,
        });

        res.json().then(function (responseData) {
            // Check if validation failed.
            if (res.status === 422) {
                if (options.displayValidation) {
                    let errors = responseData.errors || {};
                    for (let [field, errorMessage] of Object.entries(errors)) {
                        let message = errorMessage;

                        // Sometimes Laravel returns string, object, or double nested object.
                        while (typeof message === 'object') {
                            message = Object.values(message)[0];
                        }

                        let target = options.validationTarget;
                        if (target == null && field.indexOf('.') === -1) {
                            target = form.querySelector('#' + field);
                        }
                        if (target == null) {
                            target = form.querySelector(`[name="${field}"]`);
                        }
                        if (target == null) {
                            target = form.querySelector(`[name="${field}[]"]`);
                        }
                        if (target == null && field.indexOf('.') !== -1) {
                            let fieldParts = field.split('.');
                            target = form.querySelector(`[name="${fieldParts[0]}[]"]`);
                            if (target == null) {
                                // Transform "field1.field2.field3" into "field1[field2][field3]".
                                const name = fieldParts.shift() + fieldParts.map((p) => `[${p}]`).join('');
                                target = form.querySelector(`[name="${name}"]`);
                            }
                        }

                        if (target != null && !target.classList.contains('hide-validation')) {
                            if (target.dataset.errorTarget) {
                                target = target.closest(target.dataset.errorTarget);
                            }

                            let errorHTML = document.createElement('div');
                            // .form-error is purely for reference purposes.
                            errorHTML.classList.add('text-danger', 'form-error');
                            errorHTML.innerText = message;
                            target.insertAdjacentElement('afterend', errorHTML);
                        }
                    }

                    // Switch to first tab that has error.
                    const firstErrorField = form.querySelector('.form-error');
                    if (firstErrorField) {
                        const tab = firstErrorField.closest('.tab-pane');
                        if (tab) {
                            const triggerEl = document.querySelector('#' + tab.getAttribute('aria-labelledby'));
                            if (triggerEl) {
                                const bsTab = bootstrap.Tab.getInstance(triggerEl);
                                if (bsTab) {
                                    bsTab.show();
                                } else {
                                    triggerEl.click();
                                }
                            }
                        }

                    }
                }
                options.error(responseData);
            } else {
                options.success(responseData);
            }
            _after(triggerElement, res.status >= 200 && res.status < 300);
        });
    }
}


