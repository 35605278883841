<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import TableFilter from '@/components/admin/datatable/tableFilter.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref, reactive } from "vue";

const props = defineProps({
    ajaxUrl: String,
    series: {
        type: Object,
        default: {},
    },
    selectedSeries: {
        type: [String, Number],
        default: null,
    }
});

const orderBy = ref('title');
const orderAsc = ref(true);
const limit = ref(10);
const searchTerm = ref(null);
const customFilter = reactive({});
filterChanged('series', props.selectedSeries);

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};

function filterChanged(key, value) {
    if (customFilter[key] == value) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all models..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <div class="d-flex gap-2 mt-2 justify-content-end">
                <TableFilter
                    label="Series"
                    :options="series"
                    :selected="selectedSeries"
                    @change="(value, label) => filterChanged('series', value)"
                />
            </div>
        </div>
    </div>

    <Datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="vehicles-list:refresh">
        <template #headings>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="title">Title</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_active"
                        class="text-center">Active</SortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="vehicle in data"
                :key="vehicle.id">
                <td>{{ vehicle.title }}</td>
                <td class="text-center">
                    <template v-if="vehicle.is_active">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td v-html="vehicle.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
