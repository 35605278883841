<script setup>
import {onMounted, ref} from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import {formatDate, isEqual} from 'date-fns';

const emit = defineEmits(['change']);
const props = defineProps({
    selected: {
        type: Date,
        default: null,
    },
    clearable: {
        type: Boolean,
        default: true,
    },
    label: {
        type: String,
        required: true,
    },
    range: {
        type: Boolean,
        default: false,
    },
    min: {
        type: Date,
        default: null,
    },
    max: {
        type: Date,
        default: null,
    },
});

const selectedDates = ref(null);
const flatpickrEl = ref(null);
const flatpickrInstance = ref(null);
const buttonLabel = ref(props.label);

onMounted(() => {
    const options = {
        defaultDate: props.selected,
        clear: props.clearable,
        dateFormat: 'Y-m-d',
        mode: props.range ? 'range' : 'single',
        onChange: (_selectedDates) => {
            selectedDates.value = _selectedDates;
            emit('change', _selectedDates.map(date => formatDate(date, 'yyyy-MM-dd')));
            updateLabel();
        },
    };

    if (props.min) {
        options.minDate = props.min;
    }

    if (props.max) {
        options.maxDate = props.max;
    }

    flatpickrInstance.value = flatpickr(flatpickrEl.value, options);
});

function updateLabel() {
    buttonLabel.value = props.label;

    if (!selectedDates.value.length) {
        return;
    }

    buttonLabel.value += ': ';

    if (!props.range) {
        buttonLabel.value += selectedDates.value.length
            ? formatDate(selectedDates.value[0], 'dd/MM/yyyy')
            : null;
        return;
    }

    if (isEqual(selectedDates.value[0], selectedDates.value[1])) {
        buttonLabel.value += formatDate(selectedDates.value[0], 'dd/MM/yyyy');
        return;
    }

    buttonLabel.value += selectedDates.value.map(date => formatDate(date, 'dd/MM/yyyy')).join(' to ');
}

function clear() {
    flatpickrInstance.value.clear();
    selectedDates.value = null;
    emit('change', null);
}

defineExpose({
    flatpickrEl,
    flatpickrInstance,
    clear,
});
</script>

<template>
    <div v-if="clearable && selectedDates" class="btn-group">
        <button type="button" class="btn btn-alt-danger" @click="clear">&times;</button>
        <button
            type="button"
            class="btn btn-alt-secondary dropdown-toggle dropdown-toggle-split"
            @click="flatpickrInstance.open()"
        >
            <span class="me-1">{{ buttonLabel }}</span>
        </button>
    </div>
    <button v-else class="btn btn-alt-secondary dropdown-toggle" @click="flatpickrInstance.open()">
        {{ buttonLabel }}
    </button>
    <div ref="flatpickrEl" style="margin-right: -0.5rem;"></div>
</template>
