<script setup>
import { useFetch } from "@/utilities/useFetch.js";
import debounce from "lodash/debounce";
import TileDetailed from "@/components/admin/utilities/oneui/tileDetailed.vue";
import TractorList from '@/components/admin/tractors/list.vue';
import PriorityDismantle from "@/components/admin/dashboard/components/priorityDismantle.vue";
import QuotesTable from "@/components/admin/dashboard/components/quotesTable.vue";
import QuoteItemsTable from "@/components/admin/dashboard/components/QuoteItemsTable.vue";
import {onMounted, ref} from "vue";

const props = defineProps({
    apiRoute: {
        type: String,
        default: "",
    },
});

const tabData = ref(null);
const currentTab = ref("priority_dismantle");

onMounted(debounce(async () => {
    let req = await useFetch(props.apiRoute);
    tabData.value = await req.json();
}, 500));
</script>

<template>
    <div v-if="tabData !== null">
        <div class="row items-push">
            <TileDetailed
                title="Outstanding Scrap Tractors"
                :value="tabData.tiles.outstanding_scrap_tractors.count"
                button-text="View Tractors"
                :button-link="tabData.tiles.outstanding_scrap_tractors.route"
                icon="fa fa-tractor"
                width="col-sm-6 col-xxl-3"
            />
            <TileDetailed
                title="Last Tractor Worked On"
                :value="tabData.tiles.last_tractor.title"
                button-text="Continue Working"
                :button-link="tabData.tiles.last_tractor.route"
                icon="fa fa-screwdriver-wrench"
                width="col-sm-6 col-xxl-3"
            />
            <TileDetailed
                title="Hours Spent Dismantling"
                :hasButton="false"
                :value="tabData.tiles.time_spent.count"
                icon="fa fa-clock"
                width="col-sm-6 col-xxl-3"
            />
            <TileDetailed
                title="Outstanding Stock Checks"
                :value="tabData.stock_check.outstanding_count"
                button-text="View Stock Checks"
                :button-link="tabData.stock_check.route"
                icon="fa fa-boxes-stacked"
                width="col-sm-6 col-xxl-3"
            />
        </div>
        <div class="block block-rounded">
            <ul class="nav nav-tabs nav-tabs-block" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'priority_dismantle'
                    }" @click="currentTab = 'priority_dismantle'" aria-selected="true">Priority Dismantle</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'scrap_tractors'
                    }" @click="currentTab = 'scrap_tractors'" aria-selected="true">Scrap Tractors</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'stock_check'
                    }" @click="currentTab = 'stock_check'" aria-selected="true">Requires Stock Check</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'stock_check_in_progress'
                    }" @click="currentTab = 'stock_check_in_progress'" aria-selected="true">Stock Check in Progress</button>
                </li>
            </ul>
            <div class="block-content block-content-full">
                <PriorityDismantle
                    v-if="currentTab === 'priority_dismantle'"
                    :ajax-url="tabData.priority_dismantle_route"
                />
                <TractorList
                    v-if="currentTab === 'scrap_tractors'"
                    :ajax-url="tabData.scrap_tractors_route"
                    stripped-version
                />
                <QuotesTable
                    v-if="currentTab === 'stock_check'"
                    :ajax-url="tabData.stock_check.list_route"
                    :columns="tabData.stock_check.list_columns"
                />
                <QuoteItemsTable
                    v-if="currentTab === 'stock_check_in_progress'"
                    :ajax-url="tabData.stock_check_in_progress.list_route"
                    :columns="tabData.stock_check_in_progress.list_columns"
                />
            </div>
        </div>
    </div>
    <div v-else class="block-content text-center">
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
