<template>
    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="picking-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="id">Order</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        width="15%"
                        orderBy="status"
                        class="text-center">Status</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="customer">Customer</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="unallocated_items_count"
                        class="text-center">Items To Pick</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="placed_at"
                        class="text-center">Placed</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="order in data"
                :key="order.id">
                <td>
                    <span class="me-1" v-if="order.quick_view_url !== null">
                        <CustomerOrderDrawer :source-url="order.quick_view_url"></CustomerOrderDrawer>
                    </span>
                    <span v-html="order.id"></span>
                </td>
                <td class="text-center"
                    v-html="order.status"></td>
                <td v-html="order.customer"></td>
                <td class="text-center">{{ order.unallocated_items_count }}</td>
                <td class="text-center">{{ order.placed_at }}</td>
                <td v-html="order.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
import datatable from '@/components/admin/utilities/datatable.vue';
import sortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref, reactive } from "vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

const props = defineProps({
    ajaxUrl: String,
});

const orderBy = ref('placed_at');
const orderAsc = ref(false);
const limit = ref(10);
const searchTerm = ref(null);
const customFilter = reactive({});

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};


function filterChanged(key, value) {
    if (customFilter[key] == value) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}
</script>
