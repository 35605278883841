<template>
    <div class="d-flex flex-column flex-sm-row justify-content-around gap-3">
        <div class="d-flex align-items-baseline justify-content-center flex-basis-100">
            <formAddress :address="billingAddress"
                         label="Billing Address"
                         :ajax-url="billingAddressRoute"
                         allow-edit
                         emit-on-copy="billingAddressCopied"
                         listen-on-copy="deliveryAddressCopied"
                         copy-button-text="Copy into Delivery"
                         @addressChanged="(data) => addressChanged('billing', data)"
                         ref="billingAddressRef">
            </formAddress>
        </div>
        <div class="d-flex align-items-baseline justify-content-center flex-basis-100">
            <formAddress :address="deliveryAddress"
                         label="Delivery Address"
                         :ajax-url="deliveryAddressRoute"
                         :search-url="deliveryAddressesRoute"
                         show-reference
                         allow-edit
                         emit-on-copy="deliveryAddressCopied"
                         listen-on-copy="billingAddressCopied"
                         copy-button-text="Copy into Billing"
                         @addressChanged="(data) => addressChanged('delivery', data)"
                         ref="deliveryAddressRef">
            </formAddress>
        </div>
    </div>
</template>

<script setup>
    import { ref, watch, reactive, nextTick, onMounted } from "vue";
    import formAddress from '@/components/admin/addresses/address.vue';

    const emit = defineEmits(['stepValueChanged']);

    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
        isInitialLoadingFinished: Boolean,
    });
    watch(props.stepsData, () => onStepsDataChange());

    let customer = props.extras.preselected.customer?.value;
    let billingAddress = {};
    const billingAddressRef = ref(null);
    const billingAddressRoute = ref(props.routes.billing_address.replace('%customer%', customer));
    let deliveryAddress = {};
    const deliveryAddressRef = ref(null);
    const deliveryAddressRoute = ref(props.routes.delivery_address.replace('%customer%', customer));
    const deliveryAddressesRoute = ref(props.routes.delivery_addresses.replace('%customer%', customer));
    let isMounted = false;

    let stepData = reactive({ billing: {}, delivery: {} });
    const addressChanged = function (key, data) {
        let addressData = JSON.parse(JSON.stringify(Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != ""))));
        delete addressData.value;
        delete addressData.actions;

        if (Object.keys(addressData).length === 0) {
            stepData[key] = null;
        } else {
            stepData[key] = addressData;
        }
        updateParentWithValues();
    }

    const updateParentWithValues = function () {
        emit('stepValueChanged', stepData);
    }

    const onStepsDataChange = function () {
        if (props.stepsData.customer && customer !== props.stepsData.customer.customer) {
            customer = props.stepsData.customer.customer;
            deliveryAddressRoute.value = props.routes.delivery_address.replace('%customer%', customer);
            deliveryAddressesRoute.value = props.routes.delivery_addresses.replace('%customer%', customer);
            billingAddressRoute.value = props.routes.billing_address.replace('%customer%', customer);
            if (isMounted) {
                billingAddressRef.value.fetchAddress();
                deliveryAddressRef.value.fetchAddress();
            } else {
                nextTick(function () {
                    billingAddressRef.value.fetchAddress();
                    deliveryAddressRef.value.fetchAddress();
                });
            }

        }
    }

    onMounted(function () {
        isMounted = true;
    });


    if (props.extras.preselected.billingAddress) {
        billingAddress = props.extras.preselected.billingAddress;
        addressChanged('billing', billingAddress);
    }

    if (props.extras.preselected.deliveryAddress) {
        deliveryAddress = props.extras.preselected.deliveryAddress;
        addressChanged('delivery', deliveryAddress);
    }

</script>
