<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref } from "vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";

const props = defineProps({
    ajaxUrl: String,
    statuses: {
        type: Object,
        default: {},
    },
    columns: Array,
});

const orderBy = ref('id');
const orderAsc = ref(false);
const limit = ref(10);
const searchTerm = ref(null);

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>

<template>
    <Datatable
        :url="ajaxUrl"
        :limit="limit"
        :orderBy="orderBy"
        :orderAsc="orderAsc"
        :search="searchTerm"
        refreshOn="quotes-list:refresh"
    >
        <template #headings>
            <SortableTh
                v-on:set-order-by="setOrder"
                :current-order-by="orderBy"
                :order-asc="orderAsc"
                order-by="id"
            >ID</SortableTh>
            <th>Part</th>
            <th>Customer</th>
            <th v-if="columns.includes('actions')"></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="quoteItem in data" :key="quoteItem.id">
                <td class="text-nowrap">
                    <QuotesDrawer v-if="quoteItem.quote.quick_view_url" :source-url="quoteItem.quote.quick_view_url" />
                    {{ quoteItem.quote.id }}
                </td>
                <td>{{ quoteItem.part }}</td>
                <td>{{ quoteItem.quote.customer }}</td>
                <td v-html="quoteItem.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
