<script setup>
import {useFetch} from "@/utilities/useFetch.js";
import debounce from "lodash/debounce";
import TileDetailed from "@/components/admin/utilities/oneui/tileDetailed.vue";
import CustomerOrderList from '@/components/admin/customerOrders/list.vue';
import {onMounted, ref} from "vue";

const props = defineProps({
    apiRoute: {
        type: String,
        default: 'none',
    },
});

const tabData = ref(null);

onMounted(() => {
    const getTabData = debounce(async function () {
        const req = await useFetch(props.apiRoute);
        tabData.value = await req.json();
    }, 500);

    getTabData();
});
</script>

<template>
    <div v-if="tabData !== null">
        <div class="row items-push">
            <TileDetailed
                title="Stock Check Orders"
                :value="tabData.tiles.stock_check"
                button-text="View Stock Checks"
                :button-link="tabData.tiles.routes.stock_check"
                icon="fa fa-file-lines"
            />
            <TileDetailed
                title="In Stock Parts"
                :value="tabData.tiles.stock_levels.in_stock"
                button-text="View Parts"
                :button-link="tabData.tiles.routes.in_stock"
                icon="fa fa-file"
            />
            <TileDetailed
                title="Low Stock Parts"
                :value="tabData.tiles.stock_levels.low_stock"
                button-text="View Parts"
                :button-link="tabData.tiles.routes.low_stock"
                icon="fa fa-file"
            />
            <TileDetailed
                title="Out of Stock Parts"
                :value="tabData.tiles.stock_levels.no_stock"
                button-text="View Parts"
                :button-link="tabData.tiles.routes.out_of_stock"
                icon="fa fa-file"
            />
        </div>
        <div class="block block-rounded">
            <ul class="nav nav-tabs nav-tabs-block" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" aria-selected="true">Stock Check</button>
                </li>
            </ul>
            <div class="block-content block-content-full">
                <CustomerOrderList :ajax-url="tabData.stock_check.list_route" stripped-version />
            </div>
        </div>
    </div>
    <div class="block-content text-center" v-else>
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
