<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import TableFilter from '@/components/admin/datatable/tableFilter.vue';
import PartDrawer from '@/components/admin/parts/partDrawer.vue';
import {ref, reactive, computed, onMounted, nextTick} from "vue";

const props = defineProps({
    ajaxUrl: String,
    stockLevels: {
        type: Array,
        default: () => [],
    },
    categories: {
        type: Array,
        default: () => [],
    },
    stockFilter: {
        type: String,
        default: "",
    },
});

const orderBy = ref('name');
const orderAsc = ref(true);
const limit = ref(10);
const searchTerm = ref(null);
const customFilter = reactive({});
const selectedStockFilter = computed(() => {
    return customFilter['stock_level'] || props.stockFilter;
});

onMounted(() => {
    if (props.stockFilter) {
        nextTick(() => filterChanged('stock_level', props.stockFilter));
    }
});

const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};

function filterChanged(key, value) {
    if (customFilter[key] == value || value == null) {
        delete customFilter[key];
    } else {
        customFilter[key] = value;
    }
}

defineExpose({
    filterChanged,
});
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col-md-10 ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all parts.."
                >
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <div class="d-flex gap-2 mt-2 justify-content-end">
                <TableFilter
                    label="Stock Level"
                    :options="stockLevels"
                    :selected="selectedStockFilter"
                    @change="(value, label) => filterChanged('stock_level', value)"
                />
                <TableFilter
                    label="Landing Category"
                    :options="categories"
                    @change="(value, label) => filterChanged('landing_category', value)"
                />
            </div>
        </div>
    </div>

    <Datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="parts-list:refresh"
    >
        <template #headings>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Name</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="sku">SKU</SortableTh>
            <SortableTh :currentOrderBy="orderBy">Landing Category</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="stock"
                        class="text-center">Stock</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_active"
                        class="text-center">Active</SortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="part in data"
                :key="part.id">
                <td class="text-nowrap">
                    <PartDrawer :sourceUrl="part.quick_view_url" />
                    {{ part.name }}
                </td>
                <td>{{ part.sku }}</td>
                <td>{{ part.landing_category }}</td>
                <td class="text-center"
                    v-html="part.stock"></td>
                <td class="text-center">
                    <template v-if="part.is_active">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td v-html="part.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
