<template>
    <div>
        <div class="row py-2">
            <div class="col-lg-6">
                <FileUploadContainer
                    source="uploads"
                    :existing-files="item.images"
                    :name-prefix="`items[${item.id}][images]`"
                    :temp-upload-form-url="routes.image_upload"
                    multiple
                />
            </div>
            <div class="col d-flex flex-column gap-1">
                <span>{{ itemName }}</span>
                <div>
                    <div>
                        Quantity Required: {{ item.quantity }}
                    </div>
                    <a v-if="stockUrl"
                       class="text-muted fs-sm ajax-modal"
                       :href="stockUrl">Click for current stock breakdown</a>
                </div>
                <ThreeStateToggle :name="`items[${item.id}][status]`"
                                  :options="stockStatuses"
                                  :model-value="item.stock_check_status"
                                  @update:modelValue="(newValue) => emit('status-changed', newValue)"
                                  toggle-style="traffic-light"
                                  :disabled="disabled" />
                Location
                <ThreeStateToggle :name="`items[${item.id}][location]`"
                                  :options="stockLocations"
                                  :model-value="item.stock_location"
                                  @update:modelValue="(newValue) => emit('location-changed', newValue)"
                                  :disabled="disabled" />
                <div>
                    <slot name="description" />
                </div>
                <div class="w-75">
                    <FormSelect
                        :name="`items[${item.id}][assigned_to]`"
                        :options="assignToUsers"
                        placeholder="Assign to a user"
                        :model-value="item.assigned_to"
                        clearable
                        @update:modelValue="(newValue) => item.assigned_to = newValue"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import ThreeStateToggle from "@/components/admin/utilities/threeStateToggle.vue";
    import FileUploadContainer from "@/components/admin/utilities/fileUpload/fileUploadContainer.vue";
    import FormSelect from "@/components/admin/utilities/formSelect.vue";

    const emit = defineEmits(['status-changed', 'location-changed']);
    const props = defineProps({
        item: Object,
        stockStatuses: {
            type: Array,
            required: true,
        },
        stockLocations: {
            type: Array,
            required: true,
        },
        routes: Object,
        disabled: Boolean,
        removeStockBreakdownLink: Boolean,
        assignToUsers: {
            type: Array,
            default: () => [],
        },
    });

    let itemName = props.item.part;
    let stockUrl = null;
    if (typeof props.item.part === 'object' && props.item.part.label) {
        itemName = props.item.part.label;
        if (!props.removeStockBreakdownLink) {
            stockUrl = props.routes.stock_breakdown.replace('%part_id%', props.item.part.value);
        }
    }
</script>

<style scoped lang="scss">
.form-check.form-switch .form-check-input {
    background-color: #45b7cc;
    border-color: #45b7cc;
}

.form-check.form-switch .form-check-input:checked {
    background-color: #45b7cc;
    border-color: #45b7cc;
}
</style>
